import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { graphql, useStaticQuery } from "gatsby"
import { Link } from 'gatsby'
import Heading from 'src/components/Heading'
import ButtonArrow from 'src/images/button_arrow.svg'

const ArchiveFilters = ({activeTagSlug, activeTag}) => {

    const data = useStaticQuery(graphql`
        query NewsArchiveFilterQuery {
            tags: allStoryblokEntry(filter: {field_component: {eq: "news_article"}}) {
                group(field: tag_list) {
                    fieldValue
                }
            }
        }
    `)

    const [open, setOpen] = useState(false)

    return (
        <>
            {/* Desktop Filter */}
            <div className="lg:flex hidden flex-row flex-wrap gap-x-4 gap-y-4 last:bg-red">
                <Link
                    to="/news/"
                    className={`uppercase font-bold text-sm tracking-widest duration-300 hover:text-orange after:content-['鈥�'] after:text-xs after:ml-3 hover:after:text-black hover:after:content-['鈥�'] ${!activeTagSlug ? '!text-orange after:text-black' : ''}`}
                >
                All news
                </Link>
                {data.tags.group.map((item) => {
                    const slug = item.fieldValue.replace(/\s/g, "-").toLowerCase()
                    return (
                        <Link
                            to={`/news/category/${slug}/`}
                            className={`uppercase font-bold text-sm tracking-[0.075em] duration-300 last:after:!content-[''] after:content-['鈥�'] after:text-xs after:ml-4 ${activeTagSlug === slug ? `!text-orange after:text-black` : `hover:text-orange hover:after:text-black hover:after:content-['鈥�']`}`}
                            key={item.fieldValue}
                        >
                        {item.fieldValue}
                        </Link>
                    )
                })}
            </div>
            {/* Mobile Filter */}
            <div 
                className="lg:hidden block focus:outline-none bg-[#1C1B1A] cursor-pointer py-3 text-lg uppercase font-display text-white font-medium text-center w-screen left-[calc(50%-50vw)] -transform-x-1/2 relative"
                onClick={() => setOpen(true)}
                onKeyPress={() => setOpen(true)}
                role="button"
                tabIndex="0"
            >
                FILTER
                <i className="fal fa-plus ml-2"></i>
            </div>
            <div className="lg:hidden mt-6 border-b lg:border-transparent border-gray-200 lg:pb-0 pb-4">
                <Heading size="h3" className="">{!activeTag ? 'All news' : activeTag}</Heading>
            </div>
            {/* Mobile Filter Popup */}
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-[999999999999] inset-0 overflow-y-auto" onClose={setOpen}>
                    <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden align-middle h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 sm:scale-95"
                            enterTo="opacity-100 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 sm:scale-100"
                            leaveTo="opacity-0 sm:scale-95"
                        >
                            <div className="inline-block min-h-screen w-full align-bottom bg-[#E5E5E5] text-left overflow-hidden shadow-xl transform transition-all">
                                <div className="bg-white pb-3 pt-6 shadow px-6 flex flex-row justify-between items-center">
                                    <button className="text-black text-lg flex items-center w-full focus:outline-none font-medium" onClick={() => setOpen(false)}>
                                        <ButtonArrow className="text-black mr-4 rotate-[225deg]"/>
                                        Filters
                                    </button>
                                    <button
                                        type="button"
                                        className="bg-white rounded text-orange focus:outline-none"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-7 w-7" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="w-full px-6 pt-12">
                                    <div className="flex flex-col gap-1">
                                        <div className="flex flex-col gap-1">
                                            <Link
                                                to="/news/"
                                                className={`focus:outline-none uppercase font-bold text-sm tracking-widest flex flex-row items-center cursor-pointer pb-4 ${!activeTagSlug ? 'text-orange' : ''}`}
                                            >
                                            <div className={`h-4 w-4 mr-2 border border-orange rounded-full ${!activeTagSlug ? 'bg-orange' : 'bg-white'}`}></div>
                                            All news
                                            </Link>
                                            {data.tags.group.map((item) => {
                                                const slug = item.fieldValue.replace(/\s/g, "-").toLowerCase()
                                                return (
                                                    <Link
                                                        to={`/news/category/${slug}/`}
                                                        className={`focus:outline-none uppercase font-bold text-sm tracking-widest flex flex-row items-center cursor-pointer pb-4 ${activeTagSlug === slug ? 'text-orange' : ''}`}
                                                        key={item.fieldValue}
                                                    >
                                                    <div className={`h-4 w-4 mr-2 border border-orange rounded-full ${activeTagSlug === slug ? 'bg-orange' : 'bg-white'}`}></div>
                                                    {item.fieldValue}
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default ArchiveFilters
